/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
// import * as features from "../../../../../../../lib/feature-helpers";
import {getPath} from "../../../../../../../lib/utils/";
import {Select, MenuItem, FormControl, InputLabel} from "@material-ui/core";

const Testy: React.FunctionComponent<any> = props => {
    const defaultSourceId = getPath(props.state, "data.configuration.defaultDataSource");
    const isIknowMedR4External = props.state.selectedDataSourceType === "DataSourceIKnowMedR4External";

    // const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", props.features.data);

    const handleSelectChange = (operation) => (e) => {
        let id = e.target.value;
        if (id === ":UNSET:") id = "";
        if (isIknowMedR4External) {
            if (operation === "all") {
                props.onChange({
                    selectedSource: id,
                    selectedSources: {
                        read: id,
                        search: id,
                        "search-type": id,
                        "search-system": id,
                        create: id,
                        update: id,
                        delete: id
                    }
                });
                return;
            }
            if (operation !== "search") {
                props.onChange({
                    selectedSources: {
                        ...props.selectedSources,
                        [operation]: id
                    }
                });
            } else {
                props.onChange({
                    selectedSources: {
                        ...props.selectedSources,
                        search: id,
                        "search-type": id,
                        "search-system": id
                    }
                });
            }
            return;
        }
        props.onChange({
            selectedSource: id,
        });
    };

    const handleSelectClick = () => {
        const elements = $("div").filter(function () {
            return $(this).css("z-index") === "2000" && $(this).css("position") === "fixed";
        });
        elements.each(function () {
            $(this).css("z-index", "10001");
        });
    };

    const renderSelect = (label, value, onChange) => (
        <FormControl fullWidth style={{ marginTop: 8 }}>
            <InputLabel>{label}</InputLabel>
            <Select value={value} fullWidth label={label}
                onChange={onChange}
                onClick={handleSelectClick}>
                <MenuItem value={":UNSET:"}>
                    Not explicitly set
                </MenuItem>
                {props.state.sources.map(src => <MenuItem key={src.id} value={src.id}>
                    {`${src.name || src.id} ${defaultSourceId === src.id ? "(default)" : ""}`}
                </MenuItem>)}
            </Select>
        </FormControl>
    );

    return (
        <div style={{ width: "100%"}}>
            {!isIknowMedR4External ? renderSelect("Data Adapters and FHIR CDRs:", props.selectedSource, handleSelectChange("all"))
                : (<div>
                    <div style={{ marginTop: "25px" }}>Set All Operations Overrides:</div>
                    {renderSelect("All operations Data Adapters and FHIR CDRs:", props.selectedSource, handleSelectChange("all"))}
                    <div style={{ marginTop: "25px" }}>Per Operations Overrides:</div>
                    {renderSelect("Read Data Adapters and FHIR CDRs:", props.selectedSources.read, handleSelectChange("read"))}
                    {renderSelect("Search Data Adapters and FHIR CDRs:", props.selectedSources.search, handleSelectChange("search"))}
                    {renderSelect("Create Data Adapters and FHIR CDRs:", props.selectedSources.create, handleSelectChange("create"))}
                    {renderSelect("Update Data Adapters and FHIR CDRs:", props.selectedSources.update, handleSelectChange("update"))}
                    {renderSelect("Delete Data Adapters and FHIR CDRs:", props.selectedSources.delete, handleSelectChange("delete"))}
                </div>)}
        </div>
    );
}

Testy.propTypes = {
    features: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedSource: PropTypes.string
};

Testy.defaultProps = {
    selectedSource: ""
}

export default Testy;
