import axiosWrapper from "./axiosWrapper";
import {
    getAuthenticationType,
    getFhirVersion,
    isFhirVersionSupported,
    getFhirVersionName,
} from "./fhir-helpers";
import getLogoStringType from "./getLogoStringType";
import * as validateId from "./validate-id";
import { getPath } from "./utils/";
import consoleLogger from "./consoleLogger";

const isUrl: Function = require("is-url");
const hl7OverHttpProcessingIdValues = ["D", "T", "P"];

export function onWStep1(
    state: any,
    dataSources: any,
    config: any,
) {
    const name: string = state.data.name ? "" : "Required field";

    const ids: Array<string> = dataSources.all.data.map((item: any): string => item.dataSourceId);
    let dataSourceId: string = "";
    dataSourceId = validateId.isUrlFriendly(state.data.dataSourceId) ? dataSourceId : "The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
    dataSourceId = validateId.isNotTooLong(state.data.dataSourceId) ? dataSourceId : "This ID is too long";
    dataSourceId = validateId.isNotTooShort(state.data.dataSourceId) ? dataSourceId : "This ID is too short";
    dataSourceId = state.data.dataSourceId ? dataSourceId : "Required field";
    dataSourceId = validateId.isUnique(state.data.dataSourceId, ids) ? dataSourceId : "This ID already exists";
    dataSourceId = validateId.isNotRestricted(state.data.dataSourceId, config.reservedIds) ? dataSourceId : "This ID is reserved";

    const phi: string = state.data.phi === true || state.data.phi === false ? "" : "Required field";

    const adapterStandard: string = state.data.__temp__fhirVersionSelected ? "" : "Required field";

    const type: string = state.data.type || !state.data.__temp__fhirVersionSelected ? "" : "Required field";

    return {
        name,
        dataSourceId,
        phi,
        adapterStandard,
        type,
    };
}

async function _onWStep2Async(
    asyncCheck: boolean,
    parentState: any,
    config: any,
    cb: Function,
): Promise<any> {
    const MESSAGES_INIT = {
        endpoint: "",
        fhirVersion: "",
        messageBanner: null,
        patientStagingDataEnabled: "",
        patientStagingDataRestUrl: "",
        sandboxId: "",
        json: "",
        fileClinicalNoteRestUrl: "",
        epicR4Endpoint: "",
        epicPrivateApiUrl: "",
        epicExternalUserId: "",
        epicExternalUserIdType: "",
        timezone: "",
        hl7OverHttpEndpoint: ""
    };

    if (parentState.data.configuration.json) {
        try {
            JSON.parse(parentState.data.configuration.json);
        } catch (e) {
            return Promise.resolve({
                ...MESSAGES_INIT,
                json: "The configuration has to be a valid JSON object!"
            })
        }
    }

    // Epic R4 External --------------------------------------------------------
    if (parentState.data.type === "DataSourceEpicR4External" && parentState.data.configuration.json) {
        let epicR4Endpoint = "";
        let epicPrivateApiUrl = "";
        let epicExternalUserId = "";
        let epicExternalUserIdType = "";
        let timezone = "";
        let hl7OverHttpEndpoint = "";
        let hl7OverHttpProcessingId = "";
        const configJSON = JSON.parse(parentState.data.configuration.json);
        if (!configJSON.epicR4Endpoint) {
            epicR4Endpoint = "Required field";
        } else if (!isUrl(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Url in field epicR4Endpoint cannot end with /";
        }
        if (configJSON.usingEpicPrivateApi) {
            if (!configJSON.epicPrivateApiUrl) {
                epicPrivateApiUrl = "Required field";
            }
            if (!configJSON.epicExternalUserId) {
                epicExternalUserId = "Required field";
            }
            if (!configJSON.epicExternalUserIdType) {
                epicExternalUserIdType = "Required field";
            }
        }
        if (configJSON.usingSchedulingWorkflow) {
            if (!configJSON.timezone) {
                timezone = "Required field";
            }
        }
        if (configJSON.useHL7OverHttp) {
            if (!configJSON.hl7OverHttpEndpoint) {
                hl7OverHttpEndpoint = "Required field";
            }
            if (configJSON.hl7OverHttpProcessingId && hl7OverHttpProcessingIdValues.indexOf(configJSON.hl7OverHttpProcessingId) < 0) {
                hl7OverHttpProcessingId = "Must be one of these single values: 'D', 'T' or 'P'";
            }
        }
        return Promise.resolve({
            ...MESSAGES_INIT,
            epicR4Endpoint,
            epicPrivateApiUrl,
            epicExternalUserId,
            epicExternalUserIdType,
            timezone,
            hl7OverHttpEndpoint,
            hl7OverHttpProcessingId
        });
    }

    // Epic R4 -----------------------------------------------------------------
    if (parentState.data.type === "DataSourceEpicR4" && parentState.data.configuration.json) {
        let epicR4Endpoint = "";
        const configJSON = JSON.parse(parentState.data.configuration.json);
        if (!configJSON.epicR4Endpoint) {
            epicR4Endpoint = "Required field";
        } else if (!isUrl(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Url in field epicR4Endpoint cannot end with /";
        }
        return Promise.resolve({
            ...MESSAGES_INIT,
            epicR4Endpoint
        });
    }

    // iKnowMed R4 -----------------------------------------------------------------
    if (parentState.data.type === "DataSourceIKnowMedR4External" && parentState.data.configuration.json) {
        let serviceEndpoint = "";
        let empiEndpoint = "";
        const configJSON = JSON.parse(parentState.data.configuration.json);
        if (!configJSON.serviceEndpoint) {
            serviceEndpoint = "Required field";
        } else if (!isUrl(configJSON.serviceEndpoint)) {
            serviceEndpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.serviceEndpoint)) {
            serviceEndpoint = "Url in field serviceEndpoint cannot end with /";
        }
        return Promise.resolve({
            ...MESSAGES_INIT,
            serviceEndpoint,
            empiEndpoint
        });
    }

    // Varian R4 -----------------------------------------------------------------
    if (parentState.data.type === "DataSourceVarianR4External" && parentState.data.configuration.json) {
        let serviceEndpoint = "";
        let fhirEndpoint= "";
        let destination = "";
        const configJSON = JSON.parse(parentState.data.configuration.json);
        if (configJSON.serviceEndpoint) {
            if (!isUrl(configJSON.serviceEndpoint)) {
                serviceEndpoint = "Invalid URL";
            } else if (/\/$/.test(configJSON.serviceEndpoint)) {
                serviceEndpoint = "Url in field serviceEndpoint cannot end with /";
            }
        }
        if (configJSON.fhirEndpoint) {
            if (!isUrl(configJSON.fhirEndpoint)) {
                fhirEndpoint = "Invalid URL";
            } else if (/\/$/.test(configJSON.fhirEndpoint)) {
                fhirEndpoint = "Url in field fhirEndpoint cannot end with /";
            }
        }
        if (!configJSON.destination) {
            destination = "Required field";
        }
        return Promise.resolve({
            ...MESSAGES_INIT,
            fhirEndpoint,
            serviceEndpoint,
            destination
        });
    }

    // Allscripts Unity -----------------------------------------------------------------
    if (parentState.data.type === "DataAdapterAllscriptsUnity" && parentState.data.configuration.json) {
        let serviceEndpoint = "";
        let hostHeader = "";
        let timeZone = "";
        // const configJSON = JSON.parse(parentState.data.configuration.json);
        // if (!configJSON.serviceEndpoint) {
        //     serviceEndpoint = "Required field";
        // } else if (!isUrl(configJSON.serviceEndpoint)) {
        //     serviceEndpoint = "Invalid URL";
        // } else if (/\/$/.test(configJSON.serviceEndpoint)) {
        //     serviceEndpoint = "Url in field serviceEndpoint cannot end with /";
        // }
        return Promise.resolve({
            ...MESSAGES_INIT,
            serviceEndpoint,
            hostHeader,
            timeZone
        });
    }

    // Athena R4 External -----------------------------------------------------------------
    if (parentState.data.type === "DataSourceAthenaR4External" && parentState.data.configuration.json) {
        let apiEndpoint = "";
        let practiceId = "";
        const configJSON = JSON.parse(parentState.data.configuration.json);
        if (!configJSON.apiEndpoint) {
            apiEndpoint = "Required field";
        } else if (!isUrl(configJSON.apiEndpoint)) {
            apiEndpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.apiEndpoint)) {
            apiEndpoint = "Url in field serviceEndpoint cannot end with /";
        }
        if (!configJSON.practiceId) {
            practiceId = "Required field";
        }
        return Promise.resolve({
            ...MESSAGES_INIT,
            apiEndpoint,
            practiceId,
        });
    }

    // G2 R4 Ext -----------------------------------------------------------------
    if (parentState.data.type === "DataSourceG2R4External" && parentState.data.configuration.json) {
        let apiEndpoint = "";
        const configJSON = JSON.parse(parentState.data.configuration.json);
        if (configJSON.useG2WebService) {
            if (!configJSON.apiEndpoint) {
                apiEndpoint = "Required field";
            } else if (!isUrl(configJSON.apiEndpoint)) {
                apiEndpoint = "Invalid URL";
            } else if (/\/$/.test(configJSON.apiEndpoint)) {
                apiEndpoint = "Url in field serviceEndpoint cannot end with /";
            }
        }
        return Promise.resolve({
            ...MESSAGES_INIT,
            apiEndpoint,
        });
    }

    // Endpoint ================================================================
    if (
        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4",
        ].includes(parentState.data.type)
        && !parentState.data.__temp__endpoint
    ) {
        return Promise.resolve({
            ...MESSAGES_INIT,
            endpoint: "Required field",
        });
    }

    if (
        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4",
        ].includes(parentState.data.type)
        && !isUrl(parentState.data.__temp__endpoint)
    ) {
        return Promise.resolve({
            ...MESSAGES_INIT,
            endpoint: "Invalid URL",
            messageBanner: {
                type: ":RED:",
                message: "`FHIR Endpoint` is not a valid URI",
            },
        });
    }

    if (
        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4",
        ].includes(parentState.data.type)
        && parentState.data.phi
        && !parentState.data.__temp__endpoint.startsWith("https://")
    ) {
        return Promise.resolve({
            ...MESSAGES_INIT,
            endpoint: "Non-SSL connections are not allowed.",
            messageBanner: {
                type: ":RED:",
                message: "This data adapter will be used with PHI. Non-SSL connections are not allowed.",
            },
        });
    }
    // =========================================================================

    // Sandbox ID check ========================================================
    if (
        parentState.data.type === "DataSourceHspc"
        && !parentState.data.configuration.sandboxId
    ) {
        return Promise.resolve({
            ...MESSAGES_INIT,
            sandboxId: "Required field",
        });
    }

    if (parentState.data.type === "DataSourceHspc") {
        const sandboxId: string = getPath(parentState, "data.configuration.sandboxId") || "";
        let sandboxIdMessage: string = "";
        let messageBannerObject = null;
        if (
            !validateId.isUrlFriendly(sandboxId.toLowerCase())
            || sandboxId.includes("-")
        ) {
            sandboxIdMessage = "Invalid Sandbox ID";
            messageBannerObject = {
                type: ":RED:",
                message: "The ID can only contain: letters of the English alphabet and numbers(0- 9). It must start with a letter and end with a letter or number.",
            };
        }
        if (!validateId.isNotTooLong(sandboxId, 20)) {
            sandboxIdMessage = "Invalid Sandbox ID";
            messageBannerObject = {
                type: ":RED:",
                message: "This ID is too long",
            };
        }
        if (!validateId.isNotTooShort(sandboxId)) {
            sandboxIdMessage = "Invalid Sandbox ID";
            messageBannerObject = {
                type: ":RED:",
                message: "This ID is too short",
            };
        }

        if (sandboxIdMessage) {
            return Promise.resolve({
                ...MESSAGES_INIT,
                sandboxId: sandboxIdMessage,
                messageBanner: messageBannerObject,
            });
        }
    }
    // =========================================================================

    let messages = { ...MESSAGES_INIT };

    // Epic Patient Staging Data -----------------------------------------------
    if (parentState.data.type === "DataSourceEpicDstu2") {
        if (
            parentState.data.configuration.patientStagingDataEnabled !== true
            && parentState.data.configuration.patientStagingDataEnabled !== false
        ) {
            messages = {
                ...messages,
                patientStagingDataEnabled: "Required field",
            };
        }

        if (parentState.data.configuration.patientStagingDataEnabled) {
            if (!parentState.data.configuration.patientStagingDataRestUrl) {
                messages = {
                    ...messages,
                    patientStagingDataRestUrl: "Required field",
                };
            }
            else if (!isUrl(parentState.data.configuration.patientStagingDataRestUrl)) {
                messages = {
                    ...messages,
                    patientStagingDataRestUrl: "Invalid URL",
                };
            }
        }

        if (parentState.data.configuration.fileClinicalNoteEnabled) {
            if (!parentState.data.configuration.fileClinicalNoteRestUrl) {
                messages = {
                    ...messages,
                    fileClinicalNoteRestUrl: "Required field",
                };
            }
            else if (!isUrl(parentState.data.configuration.fileClinicalNoteRestUrl)) {
                messages = {
                    ...messages,
                    fileClinicalNoteRestUrl: "Invalid URL",
                };
            }
        }
    }

    // Skip async check ========================================================
    if (!asyncCheck) {
        cb();
        return { ...messages };
    }
    // =========================================================================

    // Perform async endpoint check ============================================
    if ([
        "DataSourceFhirDstu2Proxy",
        "DataSourceAllscriptsDstu2",
        "DataSourceCernerDstu2",
        "DataSourceEpicDstu2",
        "DataSourceFhirStu3Proxy",
        "DataSourceFhirR4Proxy",
        "DataSourceCernerR4",
        "DataSourceDstu2ToR4",
    ].includes(parentState.data.type)) {
        const endpointUrl: string = parentState.data.__temp__endpoint.replace(/\/$/, "") + "/metadata";
        const path: string = "api/system-config/metadata?url=" + endpointUrl;
        let response: any;
        try {
            response = await axiosWrapper(config.envApi, path);
        } catch (reason) {
            consoleLogger.log("::: reason:", reason, typeof reason);
            cb();
            return {
                ...messages,
                endpoint: reason.toString(),
                messageBanner: {
                    type: ":RED:",
                    message: `Bad FHIR URL, cannot determine FHIR version. ${reason.toString()}`,
                    // message: `Bad FHIR URL, cannot determine FHIR version. ${getPath(reason, "response.data.message")}`,
                },
            };
        }

        const metadata = getPath(response, "data") || {};
        const fhirVersionDetected: string = getFhirVersion(metadata);
        const authenticationType: string = getAuthenticationType(metadata);
        cb(fhirVersionDetected, authenticationType);

        let fhirVersionMessage: string = "";
        let messageBannerObject = null;
        const isDstu2ToR4SpecialCase = parentState.data.type === "DataSourceDstu2ToR4";
        if (!isFhirVersionSupported(fhirVersionDetected)) {
            fhirVersionMessage = "Not supported FHIR version";
            messageBannerObject = {
                type: ":YELLOW:",
                message: `The FHIR version at this endpoint (${fhirVersionDetected}), is not yet supported as an interopiO data adapter.`
            };
        }
        else if (!isDstu2ToR4SpecialCase && !fhirVersionDetected.startsWith(parentState.data.__temp__fhirVersionSelected.substr(0, 1))) {
            fhirVersionMessage = "FHIR versions does not match";
            messageBannerObject = {
                type: ":YELLOW:",
                message: `FHIR versions does not match. It has to be ${parentState.data.__temp__fhirVersionSelected}`,
            };
        }
        else if (isDstu2ToR4SpecialCase && !fhirVersionDetected.startsWith("1.0.2")) {
            fhirVersionMessage = "FHIR versions does not match";
            messageBannerObject = {
                type: ":YELLOW:",
                message: `FHIR versions does not match. It has to be "1.0.2"`,
            };
        }
        return {
            ...messages,
            fhirVersion: fhirVersionMessage,
            messageBanner: messageBannerObject,
        };
    }
    // =========================================================================

    // Skip async endpoint check in case of "DataSourceFlatironOncoEmrDstu2" ===
    if (parentState.data.type === "DataSourceFlatironOncoEmrDstu2") {
        cb();
        return { ...messages };
    }
    // =========================================================================

    // Perform async sandbox ID check ==========================================
    if (parentState.data.type === "DataSourceHspc") {
        const sandboxId: string = getPath(parentState, "data.configuration.sandboxId") || "";
        const path: string = "api/system-config/metadata?sandboxId=" + sandboxId;
        let response: any;
        try {
            response = await axiosWrapper(config.envApi, path);
        } catch (reason) {
            consoleLogger.log("::: reason:", reason, typeof reason);
            cb();
            return {
                ...messages,
                sandboxId: reason.toString(),
                messageBanner: {
                    type: ":RED:",
                    message: `Bad Sandbox ID, cannot determine FHIR version. ${reason.toString()}`,
                },
            };
        }

        cb();

        const fhirVersionDetected: string = getPath(response, "data.fhirVersion") || "";
        let fhirVersionMessage: string = "";
        let messageBannerObject = null;
        if (!isFhirVersionSupported(fhirVersionDetected)) {
            fhirVersionMessage = "Not supported FHIR version";
            messageBannerObject = {
                type: ":YELLOW:",
                message: `FHIR version of this sandbox (${fhirVersionDetected}), is not yet supported as an interopiO data adapter.`
            };
        }
        else if (!fhirVersionDetected.startsWith(parentState.data.__temp__fhirVersionSelected.substr(0, 1))) {
            fhirVersionMessage = "FHIR versions do not match";
            messageBannerObject = {
                type: ":YELLOW:",
                message: `The data adapter is ${getFhirVersionName(parentState.data.__temp__fhirVersionSelected)}, but the sandbox selected is ${getFhirVersionName(fhirVersionDetected)}`,
            };
        }

        return {
            ...messages,
            fhirVersion: fhirVersionMessage,
            messageBanner: messageBannerObject,
        };
    }
    // =========================================================================
}

let wstep2Timer;
export function onWStep2Async(
    asyncCheck: boolean,
    parentState: any,
    config: any,
    cb: Function = () => { },
) {
    clearTimeout(wstep2Timer);
    return new Promise((resolve) => {
        wstep2Timer = setTimeout((): void => resolve(_onWStep2Async(asyncCheck, parentState, config, cb)), 550);
    });
}

async function _onEditDetails(asyncCheck: boolean, state: any, config: any): Promise<any> {
    const name: string = state.data.name ? "" : "Required field";
    let json: string = "";
    let endpoint: string = "";
    let patientStagingData: string = "";
    let patientStagingDataRestUrl: string = "";
    let fileClinicalNoteRestUrl: string = "";
    let epicR4Endpoint: string = "";
    let epicPrivateApiUrl: string = "";
    let epicExternalUserId: string = "";
    let epicExternalUserIdType: string = "";
    let timezone: string = "";
    let hl7OverHttpEndpoint: string = "";
    let hl7OverHttpProcessingId: string = "";
    let serviceEndpoint = "";
    let fhirEndpoint = "";
    let empiEndpoint = "";
    let destination = "";
    let apiEndpoint = "";
    let practiceId = "";

    // Endpoint ================================================================
    const type: string = state.data.type || "";
    let endpointProperty = "";
    switch (type) {
        case "DataSourceFhirDstu2Proxy":
            endpointProperty = "fhirDstu2Endpoint";
            break;

        case "DataSourceAllscriptsDstu2":
            endpointProperty = "allscriptsDstu2Endpoint" ;
            break;

        case "DataSourceCernerDstu2":
            endpointProperty = "cernerDstu2Endpoint";
            break;

        case "DataSourceEpicDstu2":
            endpointProperty = "epicDstu2Endpoint"
            break;

        case "DataSourceFlatironOncoEmrDstu2":
            endpointProperty = "oncoEmrEndpoint";
            break;

        case "DataSourceFhirStu3Proxy":
            endpointProperty = "fhirStu3Endpoint";
            break;

        case "DataSourceFhirR4Proxy":
            endpointProperty = "fhirR4Endpoint";
            break;

        case "DataSourceCernerR4":
            endpointProperty = "cernerR4Endpoint";
            break;
        case "DataSourceDstu2ToR4":
            endpointProperty = "dstu2Endpoint";
            break;
    }
    if (
        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4"
        ].includes(type)
        && !state.data.configuration[endpointProperty]
    ) {
        endpoint = "Required field";
    }

    if (
        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4"
        ].includes(type)
        && !isUrl(state.data.configuration[endpointProperty])
    ) {
        endpoint = "`Endpoint` is not a valid URI";
    }

    if (
        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4"
        ].includes(type)
        && state.data.phi
        && !state.data.configuration[endpointProperty].startsWith("https://")
    ) {
        endpoint = "This data adapter will be used with PHI. Non-SSL connections are not allowed.";
    }
    // =========================================================================

    // Epic Patient Staging Data -----------------------------------------------
    if (type === "DataSourceEpicDstu2") {
        if (
            state.data.configuration.patientStagingDataEnabled !== true
            && state.data.configuration.patientStagingDataEnabled !== false
        ) {
            patientStagingData = "Required field";
        }

        if (state.data.configuration.patientStagingDataEnabled) {
            if (!state.data.configuration.patientStagingDataRestUrl) {
                patientStagingDataRestUrl = "Required field";
            }
            else if (!isUrl(state.data.configuration.patientStagingDataRestUrl)) {
                patientStagingDataRestUrl = "Invalid URL";
            }
        }

        if (state.data.configuration.fileClinicalNoteEnabled) {
            if (!state.data.configuration.fileClinicalNoteRestUrl) {
                fileClinicalNoteRestUrl = "Required field";
            }
            else if (!isUrl(state.data.configuration.fileClinicalNoteRestUrl)) {
                fileClinicalNoteRestUrl = "Invalid URL";
            }
        }
    }

    if (state.data.configuration.json) {
        try {
            JSON.parse(state.data.configuration.json);
        } catch (e) {
            json = "The configuration has to be a valid JSON object!";
        }
    }

    // Epic R4 External --------------------------------------------------------
    if (state.data.type === "DataSourceEpicR4External" && state.data.configuration.json) {
        const configJSON = JSON.parse(state.data.configuration.json);
        if (!configJSON.epicR4Endpoint) {
            epicR4Endpoint = "Required field";
        } else if (!isUrl(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Url in field epicR4Endpoint cannot end with /";
        }
        if (configJSON.usingEpicPrivateApi) {
            if (!configJSON.epicPrivateApiUrl) {
                epicPrivateApiUrl = "Required field";
            }
            if (!configJSON.epicExternalUserId) {
                epicExternalUserId = "Required field";
            }
            if (!configJSON.epicExternalUserIdType) {
                epicExternalUserIdType = "Required field";
            }
        }
        if (configJSON.usingSchedulingWorkflow) {
            if (!configJSON.timezone) {
                timezone = "Required field";
            }
        }
        if (configJSON.useHL7OverHttp) {
            if (!configJSON.hl7OverHttpEndpoint) {
                hl7OverHttpEndpoint = "Required field";
            }
            if (configJSON.hl7OverHttpProcessingId && hl7OverHttpProcessingIdValues.indexOf(configJSON.hl7OverHttpProcessingId) < 0) {
                hl7OverHttpProcessingId = "Must be one of these single values: 'D', 'T' or 'P'";
            }
        }
    }

    // Epic R4 -----------------------------------------------------------------
    if (state.data.type === "DataSourceEpicR4" && state.data.configuration.json) {
        const configJSON = JSON.parse(state.data.configuration.json);
        if (!configJSON.epicR4Endpoint) {
            epicR4Endpoint = "Required field";
        } else if (!isUrl(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.epicR4Endpoint)) {
            epicR4Endpoint = "Url in field epicR4Endpoint cannot end with /";
        }
    }

    // iKnowMed R4 -----------------------------------------------------------------
    if (state.data.type === "DataSourceIKnowMedR4External" && state.data.configuration.json) {
        const configJSON = JSON.parse(state.data.configuration.json);
        if (!configJSON.serviceEndpoint) {
            serviceEndpoint = "Required field";
        } else if (!isUrl(configJSON.serviceEndpoint)) {
            serviceEndpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.serviceEndpoint)) {
            serviceEndpoint = "Url in field serviceEndpoint cannot end with /";
        }
    }

    // Varian R4 -----------------------------------------------------------------
    if (state.data.type === "DataSourceVarianR4External" && state.data.configuration.json) {
        const configJSON = JSON.parse(state.data.configuration.json);
        if (configJSON.serviceEndpoint) {
            if (!isUrl(configJSON.serviceEndpoint)) {
                serviceEndpoint = "Invalid URL";
            } else if (/\/$/.test(configJSON.serviceEndpoint)) {
                serviceEndpoint = "Url in field serviceEndpoint cannot end with /";
            }
        }
        if (configJSON.fhirEndpoint) {
            if (!isUrl(configJSON.fhirEndpoint)) {
                fhirEndpoint = "Invalid URL";
            } else if (/\/$/.test(configJSON.fhirEndpoint)) {
                fhirEndpoint = "Url in field fhirEndpoint cannot end with /";
            }
        }
        if (!configJSON.destination) {
            destination = "Required field";
        }
    }

    // Athena R4 External -----------------------------------------------------------------
    if (state.data.type === "DataSourceAthenaR4External" && state.data.configuration.json) {
        const configJSON = JSON.parse(state.data.configuration.json);
        if (!configJSON.apiEndpoint) {
            apiEndpoint = "Required field";
        } else if (!isUrl(configJSON.apiEndpoint)) {
            apiEndpoint = "Invalid URL";
        } else if (/\/$/.test(configJSON.apiEndpoint)) {
            apiEndpoint = "Url in field serviceEndpoint cannot end with /";
        }
        if (!configJSON.practiceId) {
            practiceId = "Required field";
        }
    }

    // G2 R4 Ext -----------------------------------------------------------------
    if (state.data.type === "DataSourceG2R4External" && state.data.configuration.json) {
        const configJSON = JSON.parse(state.data.configuration.json);
        if (configJSON.useG2WebService) {
            if (!configJSON.apiEndpoint) {
                apiEndpoint = "Required field";
            } else if (!isUrl(configJSON.apiEndpoint)) {
                apiEndpoint = "Invalid URL";
            } else if (/\/$/.test(configJSON.apiEndpoint)) {
                apiEndpoint = "Url in field serviceEndpoint cannot end with /";
            }
        }
    }

    // Skip async check ========================================================
    if (!asyncCheck) {
        return Promise.resolve({ name, endpoint, patientStagingData, patientStagingDataRestUrl, fileClinicalNoteRestUrl, json, epicR4Endpoint, epicPrivateApiUrl, epicExternalUserId, epicExternalUserIdType, timezone, hl7OverHttpEndpoint, hl7OverHttpProcessingId, serviceEndpoint, empiEndpoint, destination, apiEndpoint, practiceId, fhirEndpoint });
    }
    // =========================================================================

    // Perform async endpoint check ============================================
    if ([
        "DataSourceFhirDstu2Proxy",
        "DataSourceAllscriptsDstu2",
        "DataSourceCernerDstu2",
        "DataSourceEpicDstu2",
        "DataSourceFhirStu3Proxy",
        "DataSourceFhirR4Proxy",
        "DataSourceCernerR4",
        "DataSourceDstu2ToR4"
    ].includes(type)) {
        const endpointUrl: string = state.data.configuration[endpointProperty].replace(/\/$/, "") + "/metadata";
        const path: string = "api/system-config/metadata?url=" + endpointUrl;
        let response: any;
        try {
            response = await axiosWrapper(config.envApi, path);
        } catch (reason) {
            consoleLogger.log("::: reason:", reason, typeof reason);
            endpoint = `Bad FHIR URL, cannot determine FHIR version. ${reason.toString()}`;
            return Promise.resolve({ name, endpoint, patientStagingData, patientStagingDataRestUrl, fileClinicalNoteRestUrl });
        }

        let fhirVersion: string = "";
        switch (state.data.fhirVersion || state.data.configuration.fhirVersion || state.data.supportedGatewayTypes[0]) {
            case "FHIR_DSTU2__1_0_2":
                fhirVersion = "1.0.2";
                break;
            case "FHIR_STU3__3_0_1":
                fhirVersion = "3.0.1";
                break;
            case "FHIR_STU3__3_0_2":
                fhirVersion = "3.0.2";
                break;
            case "FHIR_R4__4_0_0":
                fhirVersion = "4.0.0";
                break;
            case "FHIR_R4__4_0_1":
                fhirVersion = "4.0.1";
                break;
        }

        const metadata = getPath(response, "data") || {};
        const fhirVersionDetected: string = getFhirVersion(metadata);

        let isSpecialCase = state.data.type.localeCompare("DataSourceDstu2ToR4") === 0 && fhirVersionDetected.localeCompare("1.0.2") === 0;
        if (!isSpecialCase && !isFhirVersionSupported(fhirVersionDetected)) {
            endpoint = `The FHIR version at this endpoint (${fhirVersionDetected}), is not yet supported as an interopiO data adapter.`
        }
        else if (!isSpecialCase && !fhirVersionDetected.startsWith(fhirVersion.substr(0, 1))) {
            endpoint = `FHIR versions does not match. Have to be ${fhirVersion}`;
        }
    }

    // =========================================================================
    return Promise.resolve({ name, endpoint, patientStagingData, patientStagingDataRestUrl, fileClinicalNoteRestUrl, json, epicR4Endpoint, epicPrivateApiUrl, epicExternalUserId, epicExternalUserIdType, timezone, hl7OverHttpEndpoint, hl7OverHttpProcessingId, serviceEndpoint, empiEndpoint, destination, apiEndpoint, practiceId, fhirEndpoint });
}

let editTimer;
export function onEditDetails(
    asyncCheck: boolean,
    parentState: any,
    config: any,
) {
    clearTimeout(editTimer);
    return new Promise((resolve) => {
        editTimer = setTimeout((): void => resolve(_onEditDetails(asyncCheck, parentState, config)), 1100);
    });
}

function _onEditLogoUrl(url, envApi) {
    return new Promise((resolve) => {
        if (url && getLogoStringType(url) !== ":FILE:") {
            if (!isUrl(url)) {
                resolve("This is not a valid URI");
            }
            else {
                if (url.includes('/environment-api/')) {
                    const logoUrl = url.split('/environment-api/')[1]
                    axiosWrapper(
                        envApi,
                        logoUrl,
                    ).then((res) => {
                        if (res.status === 200) {
                            resolve("")
                        } else {
                            resolve("Image doesn't exist");
                        }
                    }).catch(() => {
                        resolve("Image doesn't exist");
                    })
                } else {
                    const img = new Image();
                    img.onload = () => resolve("");
                    img.onerror = () => resolve("Image doesn't exist");
                    img.src = url;
                }
            }
        }
        else {
            resolve("");
        }
    });
};

let daLogoUrlTimer;
export function onEditLogoUrlAsync(props: any) {
    clearTimeout(daLogoUrlTimer);
    return new Promise((resolve) => {
        daLogoUrlTimer = setTimeout(() => resolve(_onEditLogoUrl(props.state.data.logoUrl, props.config.envApi)), 550);
    });
}
