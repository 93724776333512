import * as PropTypes from "prop-types"

import BooleanItem from "./BooleanItem"
import GenericItem from "./GenericItem"
import StringItem from "./StringItem"

const DEFAULT_ADAPTER_CONFIG_PROPS = [
    "dataAdapterId",
    "version",
    "standard",
];

const EPIC_PRIVATE_API_PROPS = [
    "epicPrivateApiUrl",
    "epicExternalUserId",
    "epicExternalUserIdType"
];

const HL7_API_PROPS = [
    "hl7OverHttpEndpoint",
    "hl7OverHttpProcessingId",
];

const Items = (props) => {
    function getContent(name: string) {
        let helperText = "";
        if (name === "timezone" && !props.jsonObj.usingSchedulingWorkflow) {
            return false;
        } else if (EPIC_PRIVATE_API_PROPS.includes(name) && !props.jsonObj.usingEpicPrivateApi) {
            return false
        } else if (HL7_API_PROPS.includes(name) && !props.jsonObj.useHL7OverHttp) {
            return false
        }
        if (name === "hl7OverHttpProcessingId") {
            helperText = "must be one of D,T or P"
        }
        if (name === "usingEpicPrivateApi") {
            helperText = "If true will require systemAuthEnabled to be enabled"
        }

        switch (props.configProps[name].type) {
            case "boolean":
                return <BooleanItem key={name} {...props} name={name} helperText={helperText}/>;
            case "string":
                return <StringItem key={name} {...props} name={name} helperText={helperText}/>;
        }

        return (
            <GenericItem
                key={name}
                name={name}
                value={props.configProps[name].type}
            />
        );
    }

    const keys = Object.keys(props.configProps);

    return (
        <div data-qa="items">
            {keys.map((name) => !DEFAULT_ADAPTER_CONFIG_PROPS.includes(name) ? getContent(name) : null)}
        </div>
    );
};

Items.propTypes = {
    configProps: PropTypes.object.isRequired,
    jsonObj: PropTypes.object.isRequired,
};

export default Items;
